export default [
  {
    prop: 'id',
    label: 'Mã',
    minWidth: 120,
    type: 'number'
  },
  {
    prop: 'user_name',
    label: 'Tên',
    minWidth: 120,
    type: 'text'
  },
  {
    prop: 'action',
    label: 'Hành động',
    minWidth: 120,
  },
  {
    prop: 'created_at',
    label: 'Thời gian',
    minWidth: 120,
    type: 'text'
  },
  {
    prop: 'user_type',
    label: 'Loại',
    minWidth: 120,
    type:' text'
  },
]
